import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-text-message',
  templateUrl: './text-message.component.html',
  styleUrls: ['./text-message.component.styl']
})
export class TextMessageComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event){
    const currentScrollPosition = window.pageYOffset;
    const $whatsappLink = document.getElementById('whatsapp-sticky');
    if(currentScrollPosition > 400){
      $whatsappLink.classList.add('active');
    } else {
      $whatsappLink.classList.remove('active');
    }
  }

  ngOnInit() {
  }
  
}
