import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.styl']
})
export class ServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toggleList(id){
    const $infoElement = document.getElementById(`${id}`);
    const $listItems = $infoElement.parentElement.getElementsByTagName('li');
    for (let i = 0; i <= $listItems.length - 1; i++) {
      $listItems[i].classList.remove('open')
    }
    $infoElement.classList.toggle('open');
  }

}
