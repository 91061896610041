import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { PageRoutingModule } from './routing';
import { FooterComponent } from './components/footer/footer.component';
import { NavComponent } from './components/nav/nav.component';
import { ServicesComponent } from './pages/services/services.component';
import { MethodologyComponent } from './pages/methodology/methodology.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TextMessageComponent } from './components/text-message/text-message.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    NotFoundComponent,
    FooterComponent,
    NavComponent,
    ServicesComponent,
    MethodologyComponent,
    ContactComponent,
    TextMessageComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    PageRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    TextMaskModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
