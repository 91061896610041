import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.styl']
})

export class NavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  toggleMenu(){
    const $navigation = document.getElementById('app-navigation');
    const $navigationIcon = document.getElementById('app-menu-icon');
    if($navigation.classList.contains('open')){
      $navigation.classList.remove('open');
      $navigationIcon.classList.remove('change');
    } else {
      $navigation.classList.add('open');
      $navigationIcon.classList.add('change');
    }
  }

}
