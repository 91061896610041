import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.styl']
})

export class ContactComponent implements OnInit {

  public emailForm: FormGroup;
  public sendButtonLabel: string = 'Enviar correo';
  public isSendingEmail: boolean = false;
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.createForm();
  }

  sendEmail(event, form){
    this.isSendingEmail = true;
    this.sendButtonLabel = "Enviando correo...";
    this.http.post<any>('https://api.v1.drasdoconsultores.com/send_form', form.value)
      .subscribe( (response)=> {
        if(response.success){
          this.createForm();
          this.isSendingEmail = false;
          this.sendButtonLabel = "Enviar correo";
          Swal.fire({
            title: "Excelente!",
            text: "Tu correo se ha enviado exitosamente, nos estaremos contactando proximamente",
            type: 'success',
            allowOutsideClick: false,
            confirmButtonText: 'Cerrar'
          });
        } else {
          this.createForm();
          this.isSendingEmail = false;
          this.sendButtonLabel = "Enviar correo";
          Swal.fire({
            title: "Oops!",
            text: "Algo salio mal, revisa que tu información este correcta e intenta de nuevo.",
            type: 'error',
            allowOutsideClick: false,
            confirmButtonText: 'Cerrar'
          });
        }
      })
  }

  createForm(){
    this.emailForm = this.fb.group({
      name: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", Validators.required],
      message: ["", Validators.required]
    })
  }

}
