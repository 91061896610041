import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';

//PAGES
import {
  HomeComponent,
  AboutComponent,
  NotFoundComponent,
  PrivacyComponent,
  MethodologyComponent,
  ServicesComponent
} from './pages/index';


export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'servicios', component: ServicesComponent},
    { path: 'nuestra-metodologia', component: MethodologyComponent},
    { path: 'contacto', component: ContactComponent},
    { path: 'nosotros', component: AboutComponent },
    { path: 'aviso-de-privacidad', component: PrivacyComponent},
    { path: '**', component: NotFoundComponent },
]

export const PageRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes);